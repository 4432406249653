import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandApiResponse, addBrandApiResponse, updateBrandApiResponse, deleteBrandApiResponse } from "../../redux/apiResponse";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddFormModal from "../../includes/formModal/AddFormModal";
import UpdateFormModal from "../../includes/formModal/UpdateFormModal";
import DeleteFormModal from "../../includes/formModal/DeleteFormModal";
import { createFormData } from "../../utils";
import TableLoading from "../../includes/Loader/TableLoading";
import ImagePopup from "../../includes/imagePopup/ImagePopup";
import DataTable from "react-data-table-component";

const Brands = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const baseURL = `${import.meta.env.VITE_BASE_URL}/`;

  const { brandList, loading, isModalOpen, isUpdateModalOpen, isDeleteModalOpen, saveLoading } = useSelector((state) => state.brands);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    setIsUpdateOpen(isUpdateModalOpen);
  }, [isUpdateModalOpen]);

  useEffect(() => {
    setIsDeleteOpen(isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  useEffect(() => {
    dispatch(fetchBrandApiResponse({ toast }));
  }, [dispatch]);

  useEffect(() => {
    setFilteredCategories(brandList);
  }, [brandList]);

  useEffect(() => {
    const filtered = brandList.filter(category => 
      category.brandName.toLowerCase().includes(filterText.toLowerCase()) 
      ||
      category.brandType.toLowerCase().includes(filterText.toLowerCase())
      ||
      category.brandDescription.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [filterText, brandList]);

  const toggleDescription = (id) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const inputName = [
    [{ keyName: "brandName", type: "text", label: "Brand Name" },{ keyName: "brandType", type: "select-options", label: "Brand Type" }],
    [{ keyName: "brandLogo", type: "file", label: "Brand Logo" },{ keyName: "brandDescription", type: "text-area", label: "Brand Description" }]
  ];

  const formik = useFormik({
    initialValues: {
        brandName: "",
        brandType: "",
        brandLogo: "",
        brandDescription: ""
    },
    validationSchema: Yup.object({
        brandName: Yup.string().max(100, "Must be 100 characters or less").required("Required"),
        brandType: Yup.string().max(100, "Must be 100 characters or less").required("Required"),
        brandLogo: Yup.string().required("Required"),
        brandDescription: Yup.string().max(2000, "Must be 2000 characters or less").required("Required")
    }),
    onSubmit: (formData) => {
      dispatch(addBrandApiResponse({ formData: createFormData(formData), toast }));
    },
  });

  const updateFormik = useFormik({
    initialValues: {
      _id: "",
      brandName: "",
        brandType: "",
        brandLogo: "",
        brandDescription: ""
    },
    validationSchema: Yup.object({
        brandName: Yup.string().max(100, "Must be 100 characters or less").required("Required"),
        brandType: Yup.string().max(100, "Must be 100 characters or less").required("Required"),
        brandLogo: Yup.string().optional(),
        brandDescription: Yup.string().max(2000, "Must be 2000 characters or less").required("Required")
    }),
    onSubmit: (formData) => {
      dispatch(updateBrandApiResponse({ formData, toast }));
    },
  });

  const onPatchValueHandler = (category) => {
    updateFormik.setValues({
      _id: category?._id,
      brandName: category?.brandName,
      brandType: category?.brandType,
      brandDescription: category?.brandDescription
    });
  };

  const handleDelete = (formData) => {
    dispatch(deleteBrandApiResponse({ formData, toast }));
  };

  const updateStatus = (formData) => {
    dispatch(updateCategoryApiResponse({ formData, toast }));
  };

  const columns = [
    {
      name: "SR.NO",
      width: "70px",
      cell: (row, index) => <p>{index + 1}</p>
    },
    {
      name: "Brand Name",
      width: "260px",
      cell: (category) => (
        <>
          <span style={{ marginRight: '10px' }}><ImagePopup images={[{ src: `${baseURL}${category?.brandLogo}`, alt: category?.brandName }]} /></span>
          <span>{category?.brandName}</span>
        </>
      )
    },
    {
      name: "Brand Type",
      width: "160px",
      cell: (category) => (
        <>
          <span>{category?.brandType}</span>
        </>
      )
    },
    {
      name: "Description",
      width:'350px',
      cell: (category) => (
        <div style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }} title={category?.brandDescription}>
          {expandedDescriptions[category._id] ? category.brandDescription : `${category?.brandDescription.slice(0, 140)} ${category?.brandDescription.length > 100 ? '...' : ''}`}
          {category?.brandDescription.length > 100 && (<a style={{ textDecoration: 'underline', cursor: 'pointer',color:'blue' }} title="click here" onClick={() => toggleDescription(category._id)}>
            {expandedDescriptions[category._id] ? 'Show Less' : 'View More'}
          </a>)}
        </div>
      )
    },
    {
      name: "Created",
      width:'120px',
      cell: (category) => moment(category?.createdAt).format("ll")
    },
    // {
    //   name: "Status",
    //   width:'120px',
    //   cell: (category) => category?.isActive ? <span className="active__Status" onClick={() => updateStatus({ _id: category?._id, isActive: false })}>Active</span> : <span className="inactive__Status" onClick={() => updateStatus({ _id: category?._id, isActive: true })} >Inactive</span>
    // },
    {
      name: "Actions",
      cell: (category) => (
        <>
          <UpdateFormModal inputName={inputName} formik={{ formik:updateFormik, list: ["Our Brands", "Associated Brands"].map((x) => ({ name: x, id: x })) }} isOpen={isUpdateOpen} loading={saveLoading} currentValue={{ ...category, image: baseURL + "" + category?.brandLogo }} onPatchValueHandler={(value) => onPatchValueHandler(value)} modalType="Brand" />
          <DeleteFormModal handleDelete={handleDelete} itemId={{ _id: category?._id }} isDeleteOpen={isDeleteOpen} loading={saveLoading} />
        </>
      )
    }
  ];

  return (
    <>
      <div className="midde_cont">
        <div className="container-fluid">
          <div className="row column_title">
            <div className="col-md-12">
              <div className="page_title">
                <h2>Brands</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="white_shd full margin_bottom_30">
                <div className="full graph_head">
                  <div className="heading1 margin_0">
                    <h2>Brands List</h2>
                  </div>
                  <div className="heading1 margin_0" style={{ float: "right" }}>
                    <AddFormModal inputName={inputName} formik={{ formik, list: ["Our Brands", "Associated Brands"].map((x) => ({ name: x, id: x })) }} isOpen={isOpen} loading={loading} modalType="Brand" />
                  </div>
                </div>
                <div className="table_section padding_infor_info">
                  <input
                    type="text"
                    placeholder="Filter brands..."
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    style={{ marginBottom: '10px', padding: '5px', width: '200px' }}
                  />
                  {loading ? (
                    <TableLoading />
                  ) : (
                    <>
                      {filteredCategories.length > 0 ? (
                        <DataTable columns={columns} data={filteredCategories} pagination />
                      ) : (
                        <>
                          <table className="table table-striped">
                            <thead className="error_table_head">
                              <tr>
                                <th>SR.NO</th>
                                <th>Brand Name</th>
                                <th>Brand Type</th>
                                <th>Description</th>
                                <th>Created</th>
                                {/* <th>Status</th> */}
                                <th>Actions</th>
                              </tr>
                            </thead>
                          </table>
                          <p>No brands found.</p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;


