import { createSlice } from '@reduxjs/toolkit'
import { addBrandApiResponse, deleteBrandApiResponse, fetchBrandApiResponse, updateBrandApiResponse } from '../apiResponse';

  export const brandSlice = createSlice({
    name:"brands",
    initialState:{
        brandList:[],
        error:"",
        loading:false,
        isModalOpen:false,
        isUpdateModalOpen:false,
        isDeleteModalOpen:false,
        isNavigate:false,
        saveLoading:false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(addBrandApiResponse.pending, (state, action) => {
            state.message = "";
            state.error = "";
            state.loading = true;
            state.isModalOpen = true;
        })
        .addCase(addBrandApiResponse.fulfilled, (state, action) => {
            if(!!action.payload?.response?.success){
                state.brandList = [...state.brandList,action.payload?.response?.data];
                state.message = action.payload?.response?.message;
                state.error = "";
                state.loading = false;
                state.isModalOpen = false;
            } else {
                state.loading = false;
                state.isModalOpen = true;
            }
        })
        .addCase(addBrandApiResponse.rejected, (state, action) => {
            state.message = "";
            state.error = action.error.message;
            state.loading = false;
            state.isModalOpen = true;
        })
           .addCase(fetchBrandApiResponse.pending, (state, action) => {
                state.message = "";
                state.error = "";
                state.loading = true;
                state.isModalOpen = false;
            })
            .addCase(fetchBrandApiResponse.fulfilled, (state, action) => {
                if(!!action.payload?.response?.success){
                    state.brandList = action.payload?.response?.data;
                    state.message = action.payload?.response?.message;
                    state.error = "";
                    state.loading = false;
                } else {
                    state.loading = false;
                }
            })
            .addCase(fetchBrandApiResponse.rejected, (state, action) => {
                state.message = "";
                state.error = action.error.message;
                state.loading = false;
            })
          
           .addCase(updateBrandApiResponse.pending, (state, action) => {
                state.message = "";
                state.error = "";
                state.loading = !true;
                state.isUpdateModalOpen = true;
                state.saveLoading = true;
            })
            .addCase(updateBrandApiResponse.fulfilled, (state, action) => {
                if(!!action.payload?.response?.success){
                    state.brandList = state.brandList.map((x)=> {
                         if(x._id === action.payload?.response?.data?._id ){
                            x.brandType = action?.payload?.response?.data?.brandType
                            x.brandName = action?.payload?.response?.data?.brandName
                            x.brandDescription = action?.payload?.response?.data?.brandDescription
                            x.brandLogo = action?.payload?.response?.data?.brandLogo
                            x.createdAt = action?.payload?.response?.data?.createdAt
                         }
                         return x;
                    })
                    state.message = action.payload?.response?.message;
                    state.error = "";
                    state.loading = false;
                    state.isUpdateModalOpen = false;
                } else {
                    state.loading = false;
                    state.isUpdateModalOpen = true;
                }
                state.saveLoading = false;
            })
            .addCase(updateBrandApiResponse.rejected, (state, action) => {
                state.message = "";
                state.error = action.error.message;
                state.loading = false;
                state.isUpdateModalOpen = true;
                state.saveLoading = false;
            })
           .addCase(deleteBrandApiResponse.pending, (state, action) => {
                state.message = "";
                state.error = "";
                state.loading = !true;
                state.isDeleteModalOpen = true;
                state.saveLoading = true;
            })
            .addCase(deleteBrandApiResponse.fulfilled, (state, action) => {
                if(!!action.payload?.response?.success){
                    state.brandList = state.brandList.filter((x)=> String(x._id) !== String(action.payload?.response?.data?._id))
                    state.message = action.payload?.response?.message;
                    state.error = "";
                    state.loading = false;
                    state.isDeleteModalOpen = false;
                } else {
                    state.loading = false;
                    state.isDeleteModalOpen = true;
                }
                state.saveLoading = false;
            })
            .addCase(deleteBrandApiResponse.rejected, (state, action) => {
                state.message = "";
                state.error = action.error.message;
                state.loading = false;
                state.isDeleteModalOpen = true;
                state.saveLoading = false;
            })
          
    }
  })
  
  
  export default brandSlice.reducer